<template>
  <div :class="classProp">
    <h6 v-if="!isHiddenLabel">Chọn Nhà Thuốc</h6>
    <v-select
      placeholder="Chọn Nhà Thuốc"
      v-model="pharmaInfo"
      label="name"
      :clearable="false"
      :options="organizations"
      :disabled="isDisabled"
      @input="handleChangeInventory"
    >
      <template #open-indicator="{ attributes }">
        <span v-bind="attributes">
          <feather-icon icon="ChevronDownIcon" size="20" />
        </span>
      </template>
    </v-select>
  
  </div>
</template>
<script>
import vSelect from "vue-select";
import Vue from "vue";
import { mapState } from "vuex";

export default {
  name: "SelectPharma",
  props: { isDisabled: Boolean, classProp: String, isHiddenLabel: Boolean },
  components: { vSelect },
  data() {
    return {
      pharmaInfo: {}
    };
  },
  computed: {
    ...mapState({
      userLogin: state => state.authenticate.userLogin
    }),
    organizations() {
      const userLocal =
        JSON.parse(localStorage.getItem("user")) || this.userLogin;
      const data =
        userLocal?.members
          ?.filter(itemFilter => [1, 2].includes(itemFilter?.role))
          ?.map(item => item?.org) || [];

      return data;
    }
  },
  created() {
    const pharmaLocalStorage = JSON.parse(localStorage.getItem("pharmaInfo"));

    if (pharmaLocalStorage) {
      this.pharmaInfo = pharmaLocalStorage;
    } else {
      this.pharmaInfo =
        this.organizations?.length > 0 ? this.organizations[0] : null;
    }
    Vue.prototype.$pharmacyInfo = this.pharmaInfo;
    this.$emit("onChangePharma", this.pharmaInfo, true);
    localStorage.setItem("pharmaInfo", JSON.stringify(this.pharmaInfo));
  },
  methods: {
    handleChangeInventory(pharma) {
      localStorage.setItem("pharmaInfo", JSON.stringify(pharma));
      localStorage.removeItem("inventoryInfo");
      Vue.prototype.$pharmacyInfo = pharma;
      this.$emit("onChangePharma", pharma);
      window.location.reload();
    }
  },
  destroyed() {
    this.pharmaInfo = {};
  }
};
</script>
  
  <style lang="scss" scoped>
</style>